import React, { useEffect, useState } from "react";
import axios from "axios";
import api from "../../apiServises/api";
import { useDispatch, useSelector } from "react-redux";
import 'react-data-grid/lib/styles.css';
import moment from 'moment'; // Import the moment library

import DataGrid from "react-data-grid";
import { getUser } from "../../apiServises/features/userSlice";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'

const TenderTools = () => {
  let user = useSelector(getUser);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  document.title = "ProcurBuzz - Tender Report"

  const filterValue = [

    { name: "solicitno", operator: 'startsWith', type: 'string' },
    { name: "nomenclature", operator: 'startsWith', type: 'string' },
    {
      name: "opendate", operator: 'startsWith',
      type: 'string'

    },
    {
      name: "closedate", operator: 'startsWith',
      type: 'string'
    },
    { name: "Country", operator: 'startsWith', type: 'string' },



  ];
  const getdata = async () => {
    try {
      const response = await fetch('https://api.procurbuzz.com/api/tablebatchrefs', {
        headers: { Authorization: `Bearer ${user.access_token}` },
      }); // Replace with your API endpoint
      const data = await response.json();
      // console.log(data['output']);
      setData(data['output']);
      setLoading(false);
    } catch (error) {
      // console.error('Error fetching notes:', error);
    }
  };
  useEffect(() => {
    getdata();
    // console.log(data);
    // fetchBatches();
  }, [data])

  //   useEffect(()=> {
  //     console.log('Users:',data);
  //     console.log(data.length)
  //  },[data])
  const gridStyle = { minHeight: 400 }

  const columns = [

    { name: "solicitno", header: "Tender Number", defaultWidth: 200 },
    {
      name: "opendate", header: "Open Date",

      defaultFlex: 1,

    },
    {
      name: "closedate", header: "Close Date",

      defaultFlex: 1,

    },
    { name: "nomenclature", header: "Nomenclature" },
    { name: "Country", header: "Country" },
    { name: "status", header: "Status" },
    {
      name: "url", header: "Link",
      render: ({ value }) => (
        <a href={value} target="_blank" rel="noopener noreferrer">
          Link
        </a>
      ),
    },
  ];



  if (loading) {
    return <section className="p-4 adv-src">
      <div className="container px-5 pt-5 mt-5">

        <div className="col-md-12 mb-2">
          <div className="card">
            <div className="card-head p-2" style={{ backgroundColor: '#033a58' }}>
              <h4 className="text-white">  Tender Report</h4>
            </div>
            <div className="card-body">
              Loading...
            </div>
          </div>
        </div>
      </div>
    </section>;
  }

  return (
    <section className="p-4  adv-src">
      <div className="container px-5 pt-5 mt-5">

        <div className="col-md-12 mb-2">
          <div className="card">
            <div className="card-head  p-3" style={{ backgroundColor: '#033a58' }}>
              <h3 className="text-white">  Tender Report</h3>
            </div>
            <div className="card-body">
              <ReactDataGrid columns={columns} dataSource={data} enableRowGroup={false} style={gridStyle} defaultFilterValue={filterValue} showColumnMenuTool={false} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TenderTools;
