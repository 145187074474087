import React, { useEffect, useRef, useState } from "react";
import { getUser } from "../../apiServises/features/userSlice";

import axios from "axios";
import { useSelector } from "react-redux";

const UploadBatch = () => {
  let user = useSelector(getUser);

  const [file, setFile] = useState()
  const [batches, setBatches] = useState([])
  const [batchname, setBatchname] = useState('');
  const [inputType, setInputType] = useState('');
  const [refinputType, setRefinputType] = useState([]);

  function handleChange(event) {
    setFile(event.target.files[0])
  }

  document.title = "ProcurBuzz - Tender Profile Config"

  function handleSubmit(event) {
    event.preventDefault()
    const url = 'https://api.procurbuzz.com/api/upload-batch';
    const formData = new FormData();
    formData.append('file', file);
    formData.append('batchname', batchname);
    formData.append('inputtype', inputType);
    formData.append('fileName', file.name);
    console.log(inputType);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${user.access_token}`
      },
    };

    axios.post(url, formData, config).then((response) => {
      // console.log(response.data['output']);
      setBatches([...batches, response.data['output']]);
      setFile('');
      setBatchname('');
      setInputType('');
    }).catch((res) => console.log(res));

  }
  const fetchBatches = async () => {
    try {
      const response = await fetch('https://api.procurbuzz.com/api/batches', {
        headers: { Authorization: `Bearer ${user.access_token}` },
      }); // Replace with your API endpoint
      const data = await response.json();
      // console.log(data['output']);
      setBatches(data['output']);
    } catch (error) {
      console.error('Error fetching notes:', error);
    }
  };
  const fetchInputTypes = async () => {
    // console.log(user.access_token);
    try {
      const response = await fetch('https://api.procurbuzz.com/api/input-types', {
        headers: { Authorization: `Bearer ${user.access_token}` },
      }); // Replace with your API endpoint
      const data = await response.json();
      // console.log(data['output']);
      setRefinputType(data['output']);
    } catch (error) {
      console.error('Error fetching notes:', error);
    }
  };
  useEffect(() => {
    fetchInputTypes();
    fetchBatches();
  }, [])
  return (
    <>
      <section className="p-5">
        <div className="container px-5 pt-5">

          <div className="col-md-6" style={{ margin: 'auto' }}>
            <div className="card">
              <div className="card-head p-3" style={{ backgroundColor: '#033a58' }}>
                <h4 className="text-white">Tender Notification Configuration</h4>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>

                  <div className="row p-2">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="form-label p-2">Group Name</label>
                        <input type="text" className="form-control" id="GroupName" aria-describedby="GroupName" value={batchname}
                          onChange={(e) => setBatchname(e.target.value)}
                          placeholder="Enter Group Name" />

                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label for="input-type" className="form-label p-2">Input Type</label>
                        <select className="form-control" aria-describedby="input-type" placeholder="Enter input-type" value={inputType} onChange={(e) => setInputType(e.target.value)}>
                          <option disabled>Please Select a Type</option>
                          {refinputType.map((refinputtype) => (

                            <option value={refinputtype.inputtype}>{refinputtype.inputdesc}</option>

                          ))}

                        </select>
                      </div>
                    </div>
                    <div className="col-md-12 p-2">
                      <div className="form-group">
                        <label for="input-type" className="form-label p-2">File Path</label>
                        <input type="file" className="form-control" name="file" onChange={handleChange} />
                      </div>
                      <br />
                      <button type="submit" className="btn btn-primary">Submit</button>
                    </div>

                  </div>

                </form>
              </div> </div>
          </div>



          <div className="row">
            <div className="container p-1 m-5">

              <div className="card">
                <div className="card-head p-2" style={{ backgroundColor: '#033a58' }}>
                  <h4 className="text-white">Batch List</h4>
                </div>
                <div className="card-body">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Group Name</th>
                        <th scope="col">Input Type</th>
                        <th scope="col">Input Count</th>
                        <th scope="col">Updated Dete</th>
                      </tr>
                    </thead>
                    <tbody id="rec">
                      {batches.map((batch) => (
                        <tr>
                          <td>{batch.batchname}</td>
                          <td>{batch.inputtype}</td>
                          <td>{batch.input_count}</td>
                          <td>{batch.loaddate}</td>
                        </tr>
                      ))}

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
    </>
  );
};

export default UploadBatch;
