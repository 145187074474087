import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../../apiServises/api";
import { getUser } from "../../apiServises/features/userSlice";

const Support = () => {
    document.title = "ProcurBuzz - Support"
    let user = useSelector(getUser);
    const dispatch = useDispatch();
    const [help, setHelp] = useState('');
    const [problem, setProblem] = useState('');
    const [descirption, setDescirption] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const submitHandler = async () => {
        if(help !="" && problem!="" && descirption !="" && email !="" && name !="" && phone !=""){
        let para = { help, problem, descirption, email, name, phone };
        console.log(para);
        const res = await api.post(`/support`, para ,{
            headers: { Authorization: `Bearer ${user.access_token}` },
        });
        console.log(res.data);
        if(res.data?.message==='successfully sent.')
        alert('successfully sent')
    }
        return false;

    }
    const formPreventDefault = async (e) => {
       // alert('here');  
        e.preventDefault();
        return false;
      }



    return (
        <>
            <section className="bg-gradient" style={{ height: 'auto'  }}>
                <form onSubmit={e => e.preventDefault()}>
                <div className="accordian col-md-9 p-2" style={{ margin: '0 auto' }}>
                    <div class="bg-light p-5">
                
                    <br/>
                        <div className="floatform ">
                            <h4 class="mb-4">Customer Support</h4>
                            <table className="table">
                             
                        <thead>
                            <tr>
                                <td scope="col" style={{ textAlign: 'left' }}>
                                   <p class="heading">What do you need help with?</p>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <select className="form-select form-select-sm" aria-label="Default select example" required
                                    value={help}
                                    onChange={(e) => setHelp(e.target.value)}
                                >
                                    <option selected>Select</option>
                                    <option value={'A general enquiry'}>A general enquiry</option>
                                    <option value={'Something broken'}>Something broken</option>
                                    <option value={'I have a suggestion'}>I have a suggestion</option>
                                </select>

                            </tr>
                        </tbody>
                    </table>
                </div>
                    <div className="bg-light">
                     <p class="heading">Describe the problem you're having</p>

                    </div>
                    <div className="floatform ">
                        <textarea required className="form-control" placeholder="Leave a comment here" id="floatingTextarea"
                            value={problem}
                            onChange={(e) => setProblem(e.target.value)}
                        />
                    </div>
                    <div className="bg-light">
                        <p class="heading">Give us the details</p>
                        <p>Share all the details. The more we know, the better we can help you</p>

                    </div>
                    <div className="floatform ">

                        <textarea required className="form-control" placeholder="Leave a comment here" id="floatingTextarea"
                            value={descirption}
                            onChange={(e) => setDescirption(e.target.value)}

                        />
                    </div>
                    <div className="bg-light">
                       <p class="heading">Your contact details</p>

                    </div>
                    <div className="bg-light">
                        <div className=" row p-2">
                            <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Your Name</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" id="staticEmail" defaultValue="email@example.com"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}

                                />
                            </div>
                        </div>
                        <div className=" row p-2">
                            <label htmlFor="staticEmail" className="col-sm-2 col-form-label">Email</label>
                            <div className="col-sm-10">
                                <input type="email" required className="form-control" id="staticEmail" defaultValue="email@example.com"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className=" row p-2">
                            <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Phone Number</label>
                            <div className="col-sm-10">
                                <input type="text" pattern="[6789][0-9]{9}" title="Please enter valid phone number" required className="form-control" id="inputNumber"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                            <button className="btn btn-primary" type="submit"
                                onClick={() => submitHandler()}
                            >Submit</button>
                        </div>
                    
                    </div>
                </div>
                </div>
            </form>
            </section>
        </>
    );
};

export default Support;
