import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api";

export const login = createAsyncThunk("login", async (login_data) => {
    const res = await api.post("/login", login_data, { headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': 'true' } });

    if (res.data) {
        let user_data = res.data.output.user;
        user_data.access_token = res.data.output.access_token;
        localStorage.setItem('authToken',res.data.output.access_token);
        // console.log(user);
        localStorage.setItem("user", JSON.stringify(user_data));
        return user_data;
    }
});
export const logout = createAsyncThunk("logout", async (token) => {
    try {
        const res = await api.post(
            `/logout`, {}, { headers: { Authorization: `Bearer ${token}` } }
        );
        localStorage.setItem("user", "");
        return "";
    } catch (error) {
        localStorage.setItem("user", "");
        return "";
    }

    // localStorage.setItem("user", '');
});
export const signup = createAsyncThunk("signup", async (reg_data) => {
    const res = await api.post("/register", reg_data);
    if (res.data) {
        let user_data = res.data.output.data;
        user_data.access_token = res.data.output.access_token;
        // console.log(user_data);
        // localStorage.setItem("user", JSON.stringify(user_data));
        localStorage.setItem("user", JSON.stringify(user_data));
        return user_data;
    }
});

// const saved_user = localStorage.getItem("user");
const saved_user = localStorage.getItem("user");
let user = saved_user ? JSON.parse(saved_user) : "";

const initialState = {
    user: user,
};
const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {},
    extraReducers: {
        [login.pending]: () => {
            console.log("login is pendng...");
        },
        [login.fulfilled]: (state, { payload }) => {
            console.log("login fulfilled...");
            return { user: payload };
        },
        [login.rejected]: () => {
            console.log("login is rejected!");
            alert("Invalid Credentials! Please check email/password.");
        },
        [signup.pending]: () => {
            console.log("signup is pendng...");
        },
        [signup.fulfilled]: (state, { payload }) => {
            console.log("signup fulfilled...");
            return { user: payload };
        },
        [signup.rejected]: () => {
            console.log("signup is rejected!");
        },
        [logout.fulfilled]: (state, { payload }) => {
            console.log("logout fulfilled...");
            return { user: payload };
        },
    },
});

export const getUser = (state) => state.user.user;
export default userSlice.reducer;