import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import api from "../../apiServises/api";
import { getUser, logout } from "../../apiServises/features/userSlice";
import Summary from "./Summary";

// import { CSVLink, CSVDownload } from "react-csv";

const AdvanceSearch = () => {
  document.title = "ProcurBuzz - Tender Search";
  const ref = useRef(null);

  const [search_result, setSearch_result] = useState(null);
  const [nomenclature, setNomenclature] = useState("");
  const [country, setCountry] = useState("");
  const [country_list, setCountry_list] = useState("");
  const [solicitnokey, setSolicitnokey] = useState("");
  const [opendate, setOpendate] = useState("");
  const [closedate, setClosedate] = useState("");
  const [total, setTotal] = useState(0);
  const [type, setType] = useState("");
  const [show, setShow] = useState(new Array(20).fill(false));
  const [page, setPage] = useState(1);
  const [last_page, setLast_page] = useState(1);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [sort_by, setSort_by] = useState("desc");
  const [status, setStatus] = useState("");
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();

  // const user = useSelector(getUser);


  let request = {};
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  if (!user) {
    navigate("login");
  }
  let flag = true;
  const searchHandler = async (e, page, sort_by = null) => {
    e.preventDefault();
    setPage(page);
    if (!user) {
      alert("Please login to search");
      flag = false;
    }

    if (nomenclature !== "" && nomenclature?.length < 3) {
      alert("Search keyword should be atleast 3 character!");
      flag = false;
    }
    request = {
      nomenclature,
      country,
      solicitnokey: solicitnokey.replace(/[^a-zA-Z0-9]/g, ""),
      opendate: opendate,
      closedate: closedate,
      type,
      page: page,
    };
    if (status) {
      request.filterstatus = status;
    }
    console.log('order By', sort_by)
    if (sort_by == 'null' || sort_by == 'asc') {
      request.order = 'asc'
    } else {
      request.order = 'desc';
    }
    if (request.solicitnokey !== "" && request.solicitnokey?.length < 3) {
      alert("Tender Number must be greater than 3 digits");
      flag = false;
    }
    if (opendate > closedate) {
      alert("Start Date should be less than End Date!");
      flag = false;
    }
    if (flag) {
      var element = document.getElementById("loader_img");
      element.classList.remove("d-none");
      element.classList.add("loader");
      var element2 = document.getElementById("loader_data");
      element2.classList.add("d-none");

      // console.log(request);
      try {
        const res = await api.get(`/search-advance`, {
          params: request,
          headers: { Authorization: `Bearer ${user.access_token}` },
        });
        if (res.data) {
          setSearch_result(res.data.output.data.data);
          setTotal(res.data.output.data.total);
          setFrom(res.data.output.data.from);
          setTo(res.data.output.data.to);
          setLast_page(res.data.output.data.last_page);

          var element = document.getElementById("loader_img");
          element.classList.add("d-none");
          var element2 = document.getElementById("loader_data");
      element2.classList.remove("d-none");
          window.scrollTo(0, 0)

        }
      } catch (error) {
        if (error.response.data.error === "Unauthenticated.") {
          dispatch(logout(user?.access_token));
        }
      }
    }
  };

  const getCountry = async () => {
    try {
    const res = await api.get(`/search-filter`, {
      headers: { Authorization: `Bearer ${user.access_token}` },
    })
    if (res.data)
      setCountry_list(
        res.data.output.data.country.sort((a, b) =>
          a.countryname.localeCompare(b.countryname)
        )
      );
    }
    catch(err){
      localStorage.removeItem('user')
     
      console.log(err);
    }
    // console.log(res.data.output.data.country.sort((a, b) => a.countryname.localeCompare(b.countryname)));
  };

  useEffect(() => {
   
    if (!user) {
      alert("Please login to search");
    } else {
      getCountry();
    }
    return () => {
      
    };
  },[user]);

  useEffect(() => {
    setPage(1);
  }, [country, nomenclature, solicitnokey, type]);

  // console.log(search_result);
  if (!country_list) {
    return null;
  }

  // const data = search_result?.map((item) => {
  //   return {
  //     "Solicitation Number": item.solicitation_no,
  //     Country: item.country,
  //     "Open Date": item.opendate,
  //     "Close Date": item.closedate,
  //     Status: item.status,
  //     Nomenclature: item.nomenclature,
  //   };
  // });
  // console.log(data);

  const resetHandler = () => {
    setSearch_result(null);
    setClosedate("");
    setCountry("");
    setNomenclature("");
    setSolicitnokey("");
    setOpendate("");
    setType("");
    setPage(1);
    setStatus("");
  };
  const handleClick = (e, idx) => {
    e.preventDefault();
    // console.log(show);
    let new_show = show.map((i, index) => {
      if (index === idx) return !i;
      return i;
    });
    setShow(new_show);

  };
  const nextPage = async (e) => {
   
    setPage(page + 1);
   
    if (page < last_page) searchHandler(e, page + 1);
  };
  const prevPage = async (e) => {
   
    setPage(page - 1);
    
    searchHandler(e, page - 1);
  };
  let url = `https://api.procurbuzz.com/result-export?country=${country}
  &agency=&solicitnokey=${solicitnokey}&type=${type}&opendate=${opendate}&closedate=${closedate}&nomenclature=${nomenclature}&filterstatus=${status}`;

  return (
    <>

      <section className="adv-src mx-2">
        <div className="">
          <div className="row mx-4">
            <div className="col-md-3  mt-5 mb-5 pb-5 filter">
              <div className="left-sec">
                <h3>
                  <i className="fa fa-filter" aria-hidden="true" /> Filters{" "}
                  <span />
                </h3>
                <input
                  type="text"
                  id="myInput"
                  minLength={3}
                  // onKeyUp="myFunction()"
                  placeholder="Search by Keyword..."
                  title="Type in a name"
                  value={nomenclature}
                  onChange={(e) => setNomenclature(e.target.value)}
                />
                {/* { flag===false && nomenclature?.length < 3 ?<p>Search keyword should be more than 3 character</p>:''} */}
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        {" "}
                        Country{" "}
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="filter-item-wrap">
                          <select
                            id="checkbox-61"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                          >
                            <option className="" value="" placeholder="All">
                              All
                            </option>
                            {country_list?.map((list, idx) => {
                              return (
                                list.flag === 1 && (
                                  <option
                                    className=""
                                    key={idx}
                                    value={list.id}
                                  >
                                    {list.countryname}
                                  </option>
                                )
                              );
                            })}
                          </select>
                          <div />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        {" "}
                        Tender Number{" "}
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <input
                          type="text"
                          id="s-no"
                          placeholder="Tender Number"
                          value={solicitnokey}
                          onChange={(e) => setSolicitnokey(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                        onClick={() => setType("opendate")}
                      >
                        {" "}
                        Open Date{" "}
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <h4>
                          Start Date{" "}
                          <input
                            type="date"
                            id="Test_DatetimeLocal"
                            value={opendate}
                            onChange={(e) => setOpendate(e.target.value)}
                          />
                        </h4>
                        <h4>
                          End Date {"    "}
                          <input
                            type="date"
                            id="Test_DatetimeLocal"
                            value={closedate}
                            onChange={(e) => setClosedate(e.target.value)}
                          />
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                        onClick={() => setType("closedate")}
                      >
                        {" "}
                        Close Date{" "}
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <h4>
                          {" "}
                          Start Date{" "}
                          <input
                            type="date"
                            id="Test_DatetimeLocal"
                            value={opendate}
                            onChange={(e) => setOpendate(e.target.value)}
                          />
                        </h4>
                        <h4>
                          End Date{"    "}
                          <input
                            type="date"
                            id="Test_DatetimeLocal"
                            value={closedate}
                            onChange={(e) => setClosedate(e.target.value)}
                          />
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour1">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour1"
                        aria-expanded="false"
                        aria-controls="collapseFour1"
                      // onClick={() => setType("closedate")}
                      >
                        {" "}
                        Status{" "}
                      </button>
                    </h2>
                    <div
                      id="collapseFour1"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour1"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="filter-item-wrap">
                          <select
                            id="checkbox-62"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                          >
                            <option className="" value="" placeholder="All">
                              All
                            </option>
                            <option className="" value="Closed" placeholder="">
                              Close
                            </option>
                            <option className="" value="Open" placeholder="">
                              Open
                            </option>
                          </select>
                          <div />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => searchHandler(e, 1)}
                >
                  Submit Search
                </button>
                <p>
                  <a href="#" onClick={() => resetHandler()}>
                    Reset Search
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-9 mt-5 mb-3">
              <div className="d-none" id="loader_img">
                <img src="../../../assets/images/loader.gif" className="loading" />
              </div>
              <div className="advnc-src-res" id="loader_data">
                <div className="col-md-12 mx-4">
                  {search_result?.length > 0 && (
                    <span>
                      Showing {from} to {to} of {total}
                    </span>
                  )}
                  {search_result?.length === 0 && (
                    <span className="alert-danger container text-center text-uppercase">
                      <h4>There is no data for that time range/keyword!</h4>
                    </span>
                  )}
                  {search_result?.length > 0 && (
                    // <CSVLink data={data} className="btn btn-primary float-end">
                    //   Download
                    // </CSVLink>
                    <div className="row float-end">
                      <div className="col">
                        <select
                          className="form-select form-select-sm"
                          aria-label=".form-select-sm example"
                          onChange={(e) => {

                            searchHandler(e, 1, e.target.value);
                          }}
                        >
                          <option value="">Sort by</option>
                          <option value="desc">Descending</option>
                          <option value="asc">Ascending</option>
                        </select>
                      </div>
                      <div className="col">
                        <a
                          className="btn btn-sm btn-primary float end"
                          href={url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Download
                        </a>
                      </div>
                    </div>
                  )}
                </div>

                {search_result?.map((item, idx) => {
                  return (
                    <div className="col-md-12 mx-4 my-2" key={idx}>
                      <div className="research-side">
                        <span id="req">
                          <div id="pointer">{from + idx}</div>
                          <br></br>
                          <strong className="cou">
                            Tender Number -{" "}
                          </strong>
                          {item.url ? (
                            <a href={item.url} target="_blank" className="cou" rel="noreferrer">
                              {item.solicitation_no}
                            </a>
                          ) : (
                            item.solicitation_no
                          )}{" "}
                          <h5 className="open">{item.status}</h5>
                          <NavLink
                            to={`/summary/${item.postid}`}
                            state={{ id: `${item.postid}` }}
                            params={'name'}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <h5
                              className="open bg-primary"
                            >
                              Summary
                            </h5>
                          </NavLink>
                        </span>
                        {/* <p> */}
                        {item.nomenclature?.length > 155 ? (
                          <>
                            <p>
                              {show[idx]
                                ? item.nomenclature
                                : item.nomenclature.substring(0, 155) + "..."}
                            </p>
                            <Link
                              to=""
                              onClick={(e) => {
                                handleClick(e, idx);
                              }}
                            >
                              {show[idx] ? "Read Less" : "Read More"}
                            </Link>
                          </>
                        ) : (
                          item.nomenclature
                        )}
                        <br />
                        {/* </p> */}
                        <div className="col-md-12 country-name">
                          <span className="cou">Country :</span>
                          <span className="cou">{item.country}</span>
                        </div>
                        <div className="col-md-6 country-name">
                          <span className="cou">Published :</span>
                          <span className="cou">{item.opendate}</span>
                        </div>
                        <div className="col-md-6 country-name">
                          <span className="cou">Closing Date :</span>
                          <span className="cou">{item.closedate}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {/* <table
                    className="table table-bordered table-hover"
                    style={{ tableLayout: "fixed", width: "100%" }}
                  >
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col" style={{ width: "15%" }}>
                          Solicitation Number
                        </th>
                        <th scope="col">Country</th>
                        <th scope="col">Open Date</th>
                        <th scope="col">Close Date</th>
                        <th scope="col">Status </th>
                        <th scope="col" style={{ width: "40%" }}>
                          Nomenclature
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {search_result?.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td>
                              {item.url ? (
                                <a href={item.url} target="_blank">
                                  {item.solicitation_no}
                                </a>
                              ) : (
                                item.solicitation_no
                              )}
                            </td>
                            <td>{item.country}</td>
                            <td>{item.opendate}</td>
                            <td>{item.closedate}</td>
                            <td>{item.status}</td>
                            <td>
                              {item.nomenclature.length > 55 ? (
                                <>
                                  <p>
                                    {show[idx]
                                      ? item.nomenclature
                                      : item.nomenclature.substring(0, 55) +
                                        "..."}
                                  </p>
                                  <Link
                                    to=""
                                    onClick={(e) => {
                                      handleClick(e, idx);
                                    }}
                                  >
                                    {show[idx] ? "Read Less" : "Read More"}
                                  </Link>
                                </>
                              ) : (
                                item.nomenclature
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table> */}
                {/* </div> */}
                {search_result?.length > 0 && (
                  <nav
                    className="mt-3"
                    aria-label="Page navigation example mb-4"
                  >
                    <ul className="pagination justify-content-center">
                      <li
                        className={
                          page > 1 ? "page-item" : "page-item disabled"
                        }
                      >
                        <a
                          className="page-link"
                          href="#"
                          tabIndex="-1"
                          onClick={(e) => prevPage(e)}
                        >
                          Previous
                        </a>
                      </li>
                      {[...Array(last_page)].map((data, i) => {
                        return (
                          <li className="page-item" key={i}>
                            {i < 5 && (
                              <a
                                className="page-link"
                                href="#"
                                onClick={(e) => searchHandler(e, i + 1)}
                              >
                                {page == i + 1 ? (
                                  <strong>{i + 1}</strong>
                                ) : (
                                  i + 1
                                )}
                              </a>
                            )}
                            {i >= 5 && i < 7 && i !== page - 1 && (
                              <a
                                className="page-link"
                                href="#"
                                onClick={(e) => searchHandler(e, i + 1)}
                              >
                                .
                              </a>
                            )}
                            {i >= 5 &&
                              i === page - 1 &&
                              i !== last_page - 2 &&
                              i !== last_page - 1 && (
                                <a
                                  className="page-link"
                                  href="#"
                                  onClick={(e) => searchHandler(e, i + 1)}
                                >
                                  {<strong>{i + 1}</strong>}
                                </a>
                              )}
                            {i >= 7 && i === page && i !== last_page - 2 && (
                              <a
                                className="page-link"
                                href="#"
                                onClick={(e) => searchHandler(e, i + 1)}
                              >
                                .
                              </a>
                            )}
                            {i === last_page - 2 && i >= 7 && (
                              <a
                                className="page-link"
                                href="#"
                                onClick={(e) => searchHandler(e, i + 1)}
                              >
                                {page == i + 1 ? (
                                  <strong>{i + 1}</strong>
                                ) : (
                                  i + 1
                                )}
                              </a>
                            )}
                            {i === last_page - 1 && i > 7 && (
                              <a
                                className="page-link"
                                href="#"
                                onClick={(e) => searchHandler(e, i + 1)}
                              >
                                {page == i + 1 ? (
                                  <strong>{i + 1}</strong>
                                ) : (
                                  i + 1
                                )}
                              </a>
                            )}
                          </li>
                        );
                      })}
                      <li
                        className={
                          page !== last_page
                            ? "page-item"
                            : "page-item disabled"
                        }
                      >
                        <a
                          className="page-link"
                          href="#"
                          onClick={(e) => nextPage(e)}
                        >
                          Next
                        </a>
                      </li>
                    </ul>
                  </nav>
                )}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdvanceSearch;
