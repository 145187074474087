import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import api from "../../apiServises/api";
import { getUser, logout } from "../../apiServises/features/userSlice";

const KeywordSearch = () => {
  document.title = "ProcurBuzz - Super Search"

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search_keyword, setSearch_keyword] = useState("");
  const [search_result, setSearch_result] = useState("");
  const [search_resultW, setSearch_resultW] = useState("");
  const [page, setPage] = useState(1);
  const [pageW, setPageW] = useState(1);
  const [last_page, setLast_page] = useState(1);
  const [last_pageW, setLast_pageW] = useState(1);
  const [show, setShow] = useState(new Array(20).fill(false));

  const user = useSelector(getUser);
  if (!user) {
    navigate("login");
  }
  let flag = true;

  const searchHandlerWire = async (pageW) => {
    setPageW(pageW);
    if (!user) {
      alert("Please login to search");
      flag = false;
    }
    if (search_keyword?.length < 3) {
      // alert("Search keyword should be atlest 3 character!");
      flag = false;
    }
    if (flag === true) {
      try {
        const res = await api.get(`/search-keyword`, {
          params: {
            search: search_keyword,

            page: pageW,
            flag: "wire",
          },
          headers: { Authorization: `Bearer ${user.access_token}` },
        });
        if (res.data) {
          setSearch_resultW(res.data.output.data);
          setLast_pageW(res.data.output.last_page);
        }
      } catch (error) {
        if (error.response.data.error === 'Unauthenticated.') {
          dispatch(logout(user?.access_token))
        }
      }

    }
  };
  const searchHandler = async (page) => {
    setPage(page);
    if (!user) {
      alert("Please login to search");
      flag = false;
    }
    if (search_keyword?.length < 3) {
      alert("Search keyword should be atlest 3 character!");
      flag = false;
    }
    if (flag === true) {
      const res = await api.get(`/search-keyword`, {
        params: {
          search: search_keyword,

          page: page,
        },
        headers: { Authorization: `Bearer ${user.access_token}` },
      });
      console.log(res);
      if (res.data) {
        setSearch_result(res.data.output.data);
        setLast_page(res.data.output.last_page);
      }
    }
  };
  // console.log(search_result);
  const handleClick = (e, idx) => {
    e.preventDefault();
    // console.log(show);
    let new_show = show.map((i, index) => {
      if (index === idx) return !i;
      return i;
    });
    setShow(new_show);
  };
  useEffect(() => {
    setPage(1);
    setPageW(1);
  }, [search_keyword]);
  const nextPage = () => {
    setPage(page + 1);
    console.log(page);
    searchHandler(page + 1);
  };
  const prevPage = () => {
    setPage(page - 1);
    console.log(page);
    searchHandler(page - 1);
  };
  const nextPageW = () => {
    setPageW(pageW + 1);
    console.log(pageW);
    searchHandlerWire(pageW + 1);
  };
  const prevPageW = () => {
    setPage(pageW - 1);
    console.log(pageW);
    searchHandlerWire(pageW - 1);
  };

  return (
    <>
      <section className="search-banner result-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 mx-auto">

              <div id="accordion_search_bar_container">
                <p style={{ fontSize: '40px' }}>WHAT ARE YOU LOOKING FOR?</p>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    searchHandler(page);
                    searchHandlerWire(pageW);
                  }}
                >
                  <input
                    type="search"
                    id="accordion_search_bar"
                    placeholder="Search By Keyword"
                    value={search_keyword}
                    onChange={(e) => {
                      setSearch_keyword(e.target.value);
                    }}
                  />
                  <Link to="#">
                    <i
                      className="fa fa-search"
                      aria-hidden="true"
                      type="submit"
                      onClick={() => {
                        searchHandler(page);
                        searchHandlerWire(pageW);
                      }}
                    />
                  </Link>
                </form>
                <p>Super search for RFQ, RFI, RFP, e-Procurement, News, Wired, or any of them combined in a single text box.</p>
                {/* <br></br> */}
                {search_result !== "" &&
                  search_resultW !== "" &&
                  search_result?.length === 0 &&
                  search_resultW?.length === 0 && <p>No result found!</p>}
              </div>

              {search_result?.length > 0 || search_resultW?.length > 0 ? (
                <>
                  <div className="main-table">
                    <div className="table-wrapper">
                      <div className="container-sec mt-3">
                        {/* Nav tabs */}
                        <ul className="nav nav-tabs" role="tablist">
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              data-bs-toggle="tab"
                              href="#home"
                            // onClick={() => searchHandler(1)}
                            >
                              Tender
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-bs-toggle="tab"
                              href="#menu1"
                            // onClick={() => searchHandlerWire(1)}
                            >
                              Wired
                            </a>
                          </li>
                        </ul>
                        {/* Tab panes */}
                        <div className="tab-content">
                          <div
                            id="home"
                            className="container-sec tab-pane active"
                          >
                            <br />
                            <table
                              style={{ tableLayout: "fixed", width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th style={{color:'white' }}>Published Date</th>
                                  <th style={{color:'white' }}>Country</th>
                                  <th style={{ width: "40%",color:'white' }}>Headline</th>
                                  <th style={{ width: "10%",color:'white' }}>Link</th>
                                </tr>
                              </thead>
                              <tbody>
                                {search_result?.length > 0 &&
                                  search_result?.map((data, idx) => {
                                    return (
                                      data.flag === "Tender" && (
                                        <tr key={idx} className="mb-2">
                                          <td data-column="Published Date">
                                            {data.dateadded}
                                          </td>
                                          <td data-column="Country">
                                            {data.country}
                                          </td>
                                          <td data-column="Headline">
                                            {data.nomenclature.length > 55 ? (
                                              <>
                                                <p>
                                                  {show[idx]
                                                    ? data.nomenclature
                                                    : data.nomenclature.substring(
                                                      0,
                                                      55
                                                    ) + "..."}
                                                </p>
                                                <Link
                                                  to=""
                                                  onClick={(e) =>
                                                    handleClick(e, idx)
                                                  }
                                                >
                                                  {show[idx]
                                                    ? "Read Less"
                                                    : "Read More"}
                                                </Link>
                                              </>
                                            ) : (
                                              data.nomenclature
                                            )}
                                          </td>
                                          <td>
                                            {data.url ? (
                                              <a
                                                href={data.url}
                                                target="_blank"
                                              >
                                                View
                                              </a>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                        </tr>
                                      )
                                    );
                                  })}
                              </tbody>
                            </table>
                            {search_result?.length > 0 && (
                              <nav className="mt-3" aria-label="Page navigation example my-4">
                                <ul className="pagination justify-content-center">
                                  <li
                                    className={
                                      page > 1
                                        ? "page-item"
                                        : "page-item disabled"
                                    }
                                  >
                                    <a
                                      className="page-link"
                                      href="#"
                                      tabIndex="-1"
                                      onClick={() => prevPage()}
                                    >
                                      Previous
                                    </a>
                                  </li>
                                  {[...Array(last_page)].map((data, i) => {
                                    return (
                                      <li className="page-item" key={i}>
                                        {i < 5 && (
                                          <a
                                            className="page-link"
                                            href="#"
                                            onClick={() => searchHandler(i + 1)}
                                          >
                                            {page == i + 1 ? (
                                              <strong>{i + 1}</strong>
                                            ) : (
                                              i + 1
                                            )}
                                          </a>
                                        )}
                                        {i >= 5 && i < 7 && i !== page - 1 && (
                                          <a
                                            className="page-link"
                                            href="#"
                                            onClick={() => searchHandler(i + 1)}
                                          >
                                            .
                                          </a>
                                        )}
                                        {i >= 5 &&
                                          i === page - 1 &&
                                          i !== last_page - 2 &&
                                          i !== last_page - 1 && (
                                            <a
                                              className="page-link"
                                              href="#"
                                              onClick={() =>
                                                searchHandler(i + 1)
                                              }
                                            >
                                              {<strong>{i + 1}</strong>}
                                            </a>
                                          )}
                                        {i >= 7 &&
                                          i === page &&
                                          i !== last_page - 2 &&
                                          i !== last_page - 1 && (
                                            <a
                                              className="page-link"
                                              href="#"
                                              onClick={() =>
                                                searchHandler(i + 1)
                                              }
                                            >
                                              .
                                            </a>
                                          )}
                                        {i === last_page - 2 && i >= 7 && (
                                          <a
                                            className="page-link"
                                            href="#"
                                            onClick={() => searchHandler(i + 1)}
                                          >
                                            {page == i + 1 ? (
                                              <strong>{i + 1}</strong>
                                            ) : (
                                              i + 1
                                            )}
                                          </a>
                                        )}
                                        {i === last_page - 1 && i > 7 && (
                                          <a
                                            className="page-link"
                                            href="#"
                                            onClick={() => searchHandler(i + 1)}
                                          >
                                            {page == i + 1 ? (
                                              <strong>{i + 1}</strong>
                                            ) : (
                                              i + 1
                                            )}
                                          </a>
                                        )}
                                      </li>
                                    );
                                  })}
                                  <li
                                    className={
                                      page !== last_page
                                        ? "page-item"
                                        : "page-item disabled"
                                    }
                                  >
                                    <a
                                      className="page-link"
                                      href="#"
                                      onClick={() => nextPage()}
                                    >
                                      Next
                                    </a>
                                  </li>
                                </ul>
                              </nav>
                            )}

                            {/* {search_result?.length > 0 && (
                              <nav  class="mt-3" aria-label="Page navigation example">
                                <ul className="pagination justify-content-center mt-4">
                                  <li
                                    className={
                                      page > 1
                                        ? "page-item"
                                        : "page-item disabled"
                                    }
                                  >
                                    <a
                                      className="page-link"
                                      href="#"
                                      tabIndex="-1"
                                      onClick={() => prevPage()}
                                    >
                                      Previous
                                    </a>
                                  </li>
                                  {[...Array(last_page)].map((data, i) => {
                                    return (
                                      <li className="page-item" key={i}>
                                        <a
                                          className="page-link"
                                          href="#"
                                          onClick={() => searchHandler(i + 1)}
                                        >
                                          {page == i + 1 ? (
                                            <strong>{i + 1}</strong>
                                          ) : (
                                            i + 1
                                          )}
                                        </a>
                                      </li>
                                    );
                                  })}
                                  <li
                                    className={
                                      page !== last_page
                                        ? "page-item"
                                        : "page-item disabled"
                                    }
                                  >
                                    <a
                                      className="page-link"
                                      href="#"
                                      onClick={() => nextPage()}
                                    >
                                      Next
                                    </a>
                                  </li>
                                </ul>
                              </nav>
                            )} */}
                          </div>
                          <div
                            id="menu1"
                            className="container-sec tab-pane fade"
                          >
                            <br />
                            <table
                              style={{ tableLayout: "fixed", width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th style={{color:'white' }}>Published Date</th>
                                  <th style={{color:'white' }}>Country</th>
                                  <th style={{ width: "40%",color:'white' }}>Headline</th>
                                  <th style={{ width: "10%",color:'white'  }}>Link</th>
                                </tr>
                              </thead>
                              <tbody>
                                {search_resultW?.length > 0 &&
                                  search_resultW?.map((data, idx) => {
                                    return (
                                      data.flag !== "Tender" && (
                                        <tr key={idx}>
                                          <td data-column="Published Date">
                                            {data.dateadded}
                                          </td>
                                          <td data-column="Country">
                                            {data.country}
                                          </td>
                                          <td data-column="Headline">
                                            {data.nomenclature}
                                          </td>
                                          <td>
                                            {data.url ? (
                                              <a
                                                href={data.url}
                                                target="_blank"
                                              >
                                                View
                                              </a>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                        </tr>
                                      )
                                    );
                                  })}
                              </tbody>
                            </table>
                            {search_resultW?.length > 0 &&
                              <nav className="mt-3" aria-label="Page navigation example my-4">
                                <ul className="pagination justify-content-center">
                                  <li
                                    className={
                                      pageW > 1
                                        ? "page-item"
                                        : "page-item disabled"
                                    }
                                  >
                                    <a
                                      className="page-link"
                                      href="#"
                                      tabIndex="-1"
                                      onClick={() => prevPageW()}
                                    >
                                      Previous
                                    </a>
                                  </li>
                                  {[...Array(last_pageW)].map((data, i) => {
                                    return (
                                      <li className="page-item" key={i}>
                                        {i < 5 && (
                                          <a
                                            className="page-link"
                                            href="#"
                                            onClick={() =>
                                              searchHandlerWire(i + 1)
                                            }
                                          >
                                            {pageW == i + 1 ? (
                                              <strong>{i + 1}</strong>
                                            ) : (
                                              i + 1
                                            )}
                                          </a>
                                        )}
                                        {i >= 5 && i < 7 && i !== pageW - 1 && (
                                          <a
                                            className="page-link"
                                            href="#"
                                            onClick={() =>
                                              searchHandlerWire(i + 1)
                                            }
                                          >
                                            .
                                          </a>
                                        )}
                                        {i >= 5 &&
                                          i === page - 1 &&
                                          i !== last_pageW - 2 &&
                                          i !== last_pageW - 1 && (
                                            <a
                                              className="page-link"
                                              href="#"
                                              onClick={() =>
                                                searchHandlerWire(i + 1)
                                              }
                                            >
                                              {<strong>{i + 1}</strong>}
                                            </a>
                                          )}
                                        {i >= 7 &&
                                          i === page &&
                                          i !== last_pageW - 2 &&
                                          i !== last_pageW - 1 && (
                                            <a
                                              className="page-link"
                                              href="#"
                                              onClick={() =>
                                                searchHandlerWire(i + 1)
                                              }
                                            >
                                              .
                                            </a>
                                          )}
                                        {i === last_pageW - 2 && i >= 7 && (
                                          <a
                                            className="page-link"
                                            href="#"
                                            onClick={() =>
                                              searchHandlerWire(i + 1)
                                            }
                                          >
                                            {pageW === i + 1 ? (
                                              <strong>{i + 1}</strong>
                                            ) : (
                                              i + 1
                                            )}
                                          </a>
                                        )}
                                        {i === last_pageW - 1 && i > 7 && (
                                          <a
                                            className="page-link"
                                            href="#"
                                            onClick={() =>
                                              searchHandlerWire(i + 1)
                                            }
                                          >
                                            {pageW == i + 1 ? (
                                              <strong>{i + 1}</strong>
                                            ) : (
                                              i + 1
                                            )}
                                          </a>
                                        )}
                                      </li>
                                    );
                                  })}
                                  <li
                                    className={
                                      page !== last_page
                                        ? "page-item"
                                        : "page-item disabled"
                                    }
                                  >
                                    <a
                                      className="page-link"
                                      href="#"
                                      onClick={() => nextPageW()}
                                    >
                                      Next
                                    </a>
                                  </li>
                                </ul>
                              </nav>}

                            {/* {search_resultW?.length > 0 && (
                              <nav aria-label="Page navigation example">
                                <ul className="pagination justify-content-center mt-4">
                                  <li
                                    className={
                                      pageW > 1
                                        ? "page-item"
                                        : "page-item disabled"
                                    }
                                  >
                                    <a
                                      className="page-link"
                                      href="#"
                                      tabIndex="-1"
                                      onClick={() => prevPageW()}
                                    >
                                      Previous
                                    </a>
                                  </li>
                                  {[...Array(last_pageW)].map((data, i) => {
                                    return (
                                      <li className="page-item" key={i}>
                                        <a
                                          className="page-link"
                                          href="#"
                                          onClick={() =>
                                            searchHandlerWire(i + 1)
                                          }
                                        >
                                          {pageW == i + 1 ? (
                                            <strong>{i + 1}</strong>
                                          ) : (
                                            i + 1
                                          )}
                                        </a>
                                      </li>
                                    );
                                  })}
                                  <li
                                    className={
                                      pageW !== last_pageW
                                        ? "page-item"
                                        : "page-item disabled"
                                    }
                                  >
                                    <a
                                      className="page-link"
                                      href="#"
                                      onClick={() => nextPageW()}
                                    >
                                      Next
                                    </a>
                                  </li>
                                </ul>
                              </nav>
                            )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default KeywordSearch;
