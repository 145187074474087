import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom'
import { useIdleTimer } from 'react-idle-timer'
import { getUser, logout } from "../../apiServises/features/userSlice";
const Header = () => {
  const idleTimerRef = useRef(null);
  let user = useSelector(getUser);
  const dispatch = useDispatch();
  const timeout = 30 * 60 * 1000;
  const onIdle = () => {
    if (user)
      alert("Session expired");
    dispatch(logout(user?.access_token))
  };
  const idleTimer = useIdleTimer({ timeout, onIdle })

  return (
    <>
      <section className="main-menu">
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img src={`../../../assets/images/navlogo.png`} alt="logo" style={{ width: '200px' }} />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >

              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 profile-menu">
                {user && <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Award Search
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <Link className="dropdown-item" to="advance-search">
                        <i className="fas fa-sliders-h fa-fw" /> Tender Search
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="keyword-search">
                        Super Search
                      </Link>
                    </li>
                    {/* <li>
                      <Link className="dropdown-item" to="/">
                        News Wired
                      </Link>
                    </li> */}

                    <li>
                      <Link className="dropdown-item" to="/news">
                        News Wired
                      </Link>
                    </li>
                    <hr style={{ color: 'white' }} ></hr>
                    <li>
                      <Link className="dropdown-item" to="/upload-batch">
                        Tender Config
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/tender-tools">
                        Tender Report
                      </Link>
                    </li>
                  </ul>
                </li>}
                {user &&
                  <li className="nav-item">
                    <a
                      className="nav-link "
                      href="assets/about.chm"
                      target="_blank"

                    >
                      {user ? "Help" : ""}
                    </a>
                  </li>}

                {user ? (<li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {user ? "Account" : ""}
                  </a>

                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <a href="#" className="dropdown-item">
                        Welcome :{user?.name}
                      </a>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="profile">
                        Profile
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="support">
                        Support
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="login"
                        onClick={() => dispatch(logout(user?.access_token))}
                      >
                        Log Out
                      </Link>
                    </li>
                  </ul>

                </li>
                ) : (
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <Link className="dropdown-item" to="login">
                        Login
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="signup">
                        Signup
                      </Link>
                    </li>
                  </ul>
                )}
              </ul>

            </div>
          </div>
        </nav>
      </section>
      {/* <idleTimer/> */}

    </>
  );
};

export default Header;
