import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import api from "../../apiServises/api";
import { getUser } from "../../apiServises/features/userSlice";
import axios from "axios";

const Batch = () => {

  document.title = "ProcurBuzz - Batch"


  useEffect(() => {
  }, [])
  return (
    <>
      <section style={{ height: 'auto' }}>

        <div className="container div-center">
          <div className="row">
            <div className="col-md-12 body-text pb-5">
              <div className="card">
                <div className="card-head  bg-primary p-3">
                  <h3 className="text-white">RFQ Tools</h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div class="list-group">
                      <a href="#" class="list-group-item list-group-item-action flex-column align-items-start ">
                        <div class="d-flex w-100 justify-content-between">
                          <h5 class="mb-1">Tender Number : Tender item heading </h5>
                          <small>Reviewed date: 2022-09-03</small>
                          <small>Change date: 2022-09-03</small>
                          <small>Issued date: 2022-09-03</small>
                        </div>
                        <p class="mb-1">Remark: Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.</p>
                        <small>Item Group : NAN </small>
                        <small>Status : NAN </small>
                      </a>

                    </div>
                    <div class="list-group">
                      <a href="#" class="list-group-item list-group-item-action flex-column align-items-start ">
                        <div class="d-flex w-100 justify-content-between">
                          <h5 class="mb-1">Tender : Tender item heading </h5>
                          <small>Reviewed date: 2022-09-03</small>
                          <small>Change date: 2022-09-03</small>
                          <small>Issued date: 2022-09-03</small>
                        </div>
                        <p class="mb-1">Remark: Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.</p>
                        <small>Item Group : NAN </small>
                        <small>Status : NAN </small>
                      </a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*               
    <div className="row">
    <h1>RFQ Tools</h1>
<table class="table">
  <thead>
    <tr>
      <th scope="col">Tendor</th>
      <th scope="col">Remark</th>
      <th scope="col">Reviewed Date</th>
      <th scope="col">Change Date</th>
      <th scope="col">Item Group</th>
      <th scope="col">Status </th>
      <th scope="col">Issued Date </th>
      <th scope="col">Retrun By </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td scope="row">TEst</td>
      <td scope="row">Index Under View</td>
      <td scope="row">2023-09-20</td>
      <td scope="row">2023-09-20</td>
      <td scope="row">India</td>
      <td scope="row">Pending</td>
      <td scope="row">2023-09-20</td>
      <td scope="row">Shivam</td>
      
    </tr>
    
  </tbody>
</table>
    </div> */}
        </div>
      </section>
    </>
  );
};

export default Batch;
