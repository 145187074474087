import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import api from "../../../../apiServises/api";

const ResetPassword = () => {
  document.title="ProcurBuzz - Reset Password"

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  let token = searchParams.get("token");
  // console.log(token);

  const resetHandler = async (e) => {
    e.preventDefault();
    let params = {
      email,
      password,
      password_confirmation: confirmpassword,
      token,
    };
    console.log(params);
    try {
      const res = await api.post("password/reset", params);
      console.log(res.data);
      if (res.data.message=== "Password reset successfully") {
        alert("Password reset successfully");
        navigate("/");
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      error.response.data.errors.map((i) => alert(i));
    }
  };
  return (
    <>
      <section className="banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="col-md-6"> </div>
              <div className="col-md-4 float-end">
                <div className="login-section">
                  <div className="login-head">
                    <h1>Reset Password</h1>
                  </div>
                  <form>
                    <div className="mb-3">
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="password"
                        className="form-control"
                        id="exampleInputPassword"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>

                    <div className="mb-3">
                      <input
                        type="password"
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="Confirm Password"
                        value={confirmpassword}
                        onChange={(e) => setConfirmpassword(e.target.value)}
                      />
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={(e) => resetHandler(e)}
                    >
                      Reset Password
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
