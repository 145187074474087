import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import api from "../../apiServises/api";
import { getUser } from "../../apiServises/features/userSlice";

const Summary = () => {
    document.title = "ProcurBuzz - Summary"
    let user = useSelector(getUser);
    const location = useLocation()
    const params = useParams();
    let p = params.id.split('!!');
    // const id=location.state.id;
    let id = p[0]; let t = p[1];
    console.log(id, t);
    const dispatch = useDispatch();
    const ref = useRef(null);
    const [data, setData] = useState(null);
    const showModal = async () => {
        const res = await api.get(`/search-advance/${id}`, {
            headers: { Authorization: `Bearer ${user.access_token}` },
        });
        console.log(res.data)
        if (res.data) {
            setData(res.data.output.data);
        }
        console.log(data);
    }
    useEffect(() => {
        showModal()
    }, [])
    return (
        <>
            <section className="banner" style={{ height: 'auto' }}>
                <div className="accordian col-md-9" style={{ margin: '0 auto', padding: '80px 0px' }}>
                    <h2 className="p-3 bg-white text-center">Summary</h2>
                    <table className="table tbl-summary">
                        <thead>
                            <tr>
                                <th>Tender Number</th>
                                <th>Open Date</th>
                                <th >Close Date</th>
                                <th >Title</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{data?.url ? (
                                    <a href={data?.url} target="_blank" className="cou">
                                        {data?.solicitno}
                                    </a>
                                ) : (
                                    data?.solicitno
                                )}{" "}</td>
                                <td>{data?.opendate}</td>
                                <td>{data?.closedate}</td>
                                <td>{data?.title}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="bg-light">
                        <strong><p className="p-3">Contact Information</p></strong>

                    </div>
                    <table className="table tbl-summary">

                        <tbody>
                            <tr>
                                <th>Type</th>
                                <td>{data?.proctype}</td>
                                <th>Status</th>
                                <td>{data?.status}</td>

                            </tr>
                            <tr>
                                <th>Agency</th>
                                <td>{data?.agency}</td>
                                <th>Published Date</th>
                                <td>{data?.published_date}</td>
                            </tr>
                            <tr>

                                <th>Country</th>
                                <td>{data?.countryname}</td>
                                <th>Additional Doc</th>
                                <td>{data?.adddoc}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="bg-light">
                        <strong><p className="p-3">Tender</p></strong>

                    </div>
                    <table className="table tbl-summary">
                        <thead>
                            <tr>
                                <th>Product Code</th>
                                <th>Description</th>
                                <th >Quantity</th>
                                <th >Ref. Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{data?.prodcode}</td>
                                <td>{data?.desc}</td>
                                <td>{data?.qty}</td>
                                <td>{data?.refprice}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="bg-light">
                        <strong><p className="p-3">Procedure or Service Information</p></strong>

                    </div>
                    <table className="table tbl-summary">
                        <thead>
                            <tr>
                                <th>Procurment Category</th>
                                <th>Procurment Procedure</th>
                                <th >Contract Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{data?.prodcategory || 'N/A'}</td>
                                <td>{data?.prodprocedure || 'N/A'}</td>
                                <td>{data?.contacttype || 'N/A'}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="bg-light">
                        <strong><p className="p-3">Contact Information</p></strong>

                    </div>
                    <table className="table tbl-summary">

                        <tbody>
                            <tr>
                                <th>Point Of Contact</th>
                                <td>{data?.pocfname || 'N/A'}</td>
                                <th>Phone</th>
                                <td>{data?.phone || 'N/A'}</td>

                            </tr>
                            <tr>
                                <th>Address</th>
                                <td>{data?.address || 'N/A'}</td>
                                <th>Email</th>
                                <td>{data?.email || 'N/A'}</td>
                            </tr>
                            <tr>

                                <th>City</th>
                                <td>{data?.city || 'N/A'}</td>
                                <th>Website</th>
                                <td><a href={data?.website} target="_blank">{data?.website}</a></td>
                            </tr>
                            <tr>
                                <th>Country</th>
                                <td>{data?.countryname || 'N/A'}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </section>
        </>
    );
};

export default Summary;
