import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../../apiServises/api";
import { getUser } from "../../apiServises/features/userSlice";

const Profile = () => {
  document.title = "ProcurBuzz - Profile"
  let user = useSelector(getUser);
  const dispatch = useDispatch();
  const body = {};
  const [edit, setEdit] = useState(false);
  const [change, setChange] = useState(false);
  const [profile, setProfie] = useState("");
  const [name, setName] = useState(user?.name);
  const [email, setEmail] = useState(user?.email);
  const [old_password, setOld_password] = useState();
  const [new_password, setNew_password] = useState();
  const [confirm_password, setConfirm_password] = useState();

  const getProfile = async () => {
    console.log(user.access_token);
    const res = await api.post(`/profile`, body, {
      headers: { Authorization: `Bearer ${user.access_token}` },
    });
    if (res.data) {
      console.log(res.data);
      setProfie(res.data.output.user);
    }
  };
  useEffect(() => {
    getProfile();
  }, []);

  const updateHandler = async (e) => {
    e.preventDefault();
    const res = await api.post(
      `/profile/update`,
      { name, email },
      {
        headers: { Authorization: `Bearer ${user.access_token}` },
      }
    );
    console.log(res.data);
    getProfile();
    setEdit(false);
  };
  const updateHandler2 = async (e) => {
    e.preventDefault();
    const res = await api.post(
      `/change-password`,
      { old_password, new_password, confirm_password },
      {
        headers: { Authorization: `Bearer ${user.access_token}` },
      }
    );
    if (res.data.message === 'Password updated successfully') {
      setOld_password('');
      setNew_password('');
      setConfirm_password('');
      setChange(false);
    }
    alert(res.data.message);
    setChange(false);
  };
  return (
    <>
      <section className="banner">
        <div
          className="container justify-content-center"
          style={{ display: "flex" }}
        >
          <div className="card mx-5" style={{ width: "28rem" }}>
            <img
              src="images\Searchs_002.png"
              className="card-img-top"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title">Your Profile</h5>
              <p className="card-text">{""}</p>
              <p className="card-text">{""}</p>
            </div>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <h6> Name : {profile?.name}</h6>
                <p className="card-text">{""}</p>
              </li>
              <li className="list-group-item">
                <h6>Email : {profile?.email}</h6>
                <p className="card-text">{""}</p>
              </li>
              <li className="list-group-item">
                <h6>Organization : {profile?.organization}</h6>
                <p className="card-text">{""}</p>
              </li>
              <li className="list-group-item">
                <h6>Country : {profile?.country}</h6>
                <p className="card-text">{""}</p>
              </li>
              <li className="list-group-item">
                <h6>Website : {profile?.website}</h6>
                <p className="card-text">{""}</p>
              </li>
            </ul>

            <div class="d-md-flex">
              {/* <a
                href="#"
                className="btn btn-primary my-2 m-2"
                onClick={() => setEdit(!edit)}
              >
                Edit Profile
              </a> */}
              <a
                href="#"
                className="btn btn-primary my-2 m-2"
                onClick={() => setChange(!change)}
              >
                Change Password
              </a>

            </div>
          </div>
          {edit && (
            <div className="card " style={{ width: "28rem" }}>
              <div className="card-body">
                <form>
                  <br></br>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter Name"
                      defaultValue={profile?.name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <br></br>

                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <input disabled
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                      defaultValue={profile?.email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <br></br>

                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={(e) => {
                      updateHandler(e);
                    }}
                  >
                    Update
                  </button>
                </form>
              </div>
            </div>
          )}
          {change && (
            <div className="card " style={{ width: "28rem" }}>
              <div className="card-body">
                <form>
                  <br></br>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Old Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputoldp"
                      aria-describedby="emailHelp"
                      placeholder="Enter old password"
                      value={old_password}
                      onChange={(e) => setOld_password(e.target.value)}
                    />
                  </div>
                  <br></br>

                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputnp"
                      aria-describedby="emailHelp"
                      placeholder="Enter new password"
                      value={new_password}
                      onChange={(e) => setNew_password(e.target.value)}
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Confirm New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputnpc"
                      aria-describedby="emailHelp"
                      placeholder="Confirm new password"
                      value={confirm_password}
                      onChange={(e) => setConfirm_password(e.target.value)}
                    />
                  </div>
                  <br></br>

                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={(e) => {
                      updateHandler2(e);
                    }}
                  >
                    Change Password
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Profile;
