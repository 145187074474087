import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import api from "../../apiServises/api";
import { getUser, logout } from "../../apiServises/features/userSlice";
import moment from 'moment'; // Import the moment library
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'


const News = () => {
    document.title = "ProcurBuzz - Defense News"

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [search_keyword, setSearch_keyword] = useState("");
    const [search_result, setSearch_result] = useState("");
    const [loading, setLoading] = useState(true);

    // const [show, setShow] = useState(new Array(20).fill(false));

    const user = useSelector(getUser);
    if (!user) {
        navigate("login");
    }
    let flag = true;
    const gridStyle = { minHeight: 400 }

    const columns = [


        {
            name: "dateadded", header: "Publish Date",
            // filterEditor: DateFilter,


        },

        { name: "publisher", header: "Publisher" },
        {
            name: "title", header: "Headline",
            defaultFlex: 1,

        },
        { name: "author", header: "Author" },
        { name: "source", header: "Source" },
        {
            name: "url", header: "Link",
            render: ({ value }) => (
                <a href={value} target="_blank" rel="noopener noreferrer">
                    Link
                </a>
            ),
        },
    ];

    const filterValue = [

        { name: "publisher", operator: 'startsWith', type: 'string' },
        { name: "author", operator: 'startsWith', type: 'string' },
        { name: "source", operator: 'startsWith', type: 'string' },
        { name: "title", operator: 'startsWith', type: 'string' },
        {
            name: "dateadded", operator: 'startsWith',
            type: 'string'
            ,

        },


    ];
    const searchHandlerWire = async () => {
        setLoading(true);
        // setPageW(pageW);
        if (!user) {
            alert("Please login to search");
            flag = false;
        }
        if (search_keyword?.length < 3) {
            // alert("Search keyword should be atlest 3 character!");
            flag = false;
        }
        if (flag === true) {
            try {
                const res = await api.get(`https://api.procurbuzz.com/api/news-search`, {
                    params: {
                        search: search_keyword,

                        // page: pageW,
                        // flag: "wire",
                    },
                    headers: { Authorization: `Bearer ${user.access_token}` },
                });
                if (res.data) {
                    setSearch_result(res.data.output.data);
                    // setLast_pageW(res.data.output.last_page);
                }
            } catch (error) {
                if (error.response.data.error === 'Unauthenticated.') {
                    dispatch(logout(user?.access_token))
                }
            }

        }
    };

    useEffect(() => {

    }, []);


    return (
        <>
            <section className="search-banner result-banner pb-5 mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12 mx-auto">

                            <div id="accordion_search_bar_container">
                                <p style={{ fontSize: '40px' }}> Search Global Defense News</p>
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        // searchHandler(page);
                                        searchHandlerWire();
                                    }}
                                >
                                    <input
                                        type="search"
                                        id="accordion_search_bar"
                                        placeholder="Search By Keyword"
                                        value={search_keyword}
                                        onChange={(e) => {
                                            setSearch_keyword(e.target.value);
                                        }}
                                    />
                                    <Link to="#">
                                        <i
                                            className="fa fa-search"
                                            aria-hidden="true"
                                            type="submit"
                                            onClick={() => {
                                                // searchHandler(page);
                                                searchHandlerWire();
                                            }}
                                        />
                                    </Link>
                                </form>
                                <p>ProcuBuzz defence news covers industry, government, business, market, and research news for the defence industry.</p>
                                {/* <br></br> */}
                                {search_result !== "" &&
                                    search_result?.length === 0 &&
                                    <p>No result found!</p>}                           </div>

                            {search_result?.length > 0 ? (
                                <>
                                    <section className="pb-5">
                                        <div className="container px-5">

                                            <div className="col-md-12 mb-2">
                                                <div className="card">
                                                    <div className="card-head  p-3" style={{ backgroundColor: '#033a58' }}>
                                                        <h4 className="text-white text-start">Search Result</h4>
                                                    </div>
                                                    <div className="card-body">
                                                        <ReactDataGrid columns={columns} dataSource={search_result} style={gridStyle} defaultFilterValue={filterValue} pagination onLoadingChange={setLoading} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default News;
