import { Outlet } from "react-router-dom";
import React from 'react';
// import Navbar from "./Navbar/Navbar";
import Header from "./Navbar/Header";

const LayoutUnauth = () => {
    return (
        <>
            <Header />
            <Outlet />
        </>
    )
}

export default LayoutUnauth;