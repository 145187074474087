import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import api from '../../../../apiServises/api'

const ForgotPassword = () => {
  document.title="ProcurBuzz - Forgot Password"

  const navigate=useNavigate()
  const [flag, setFlag] = useState(false);
  const [email, setEmail] = useState('');

  const forgotPasswordHandler = async (e) => {
    e.preventDefault();
    console.log(email);
    const res = await api.post('password/forgot-password',{email});
    if(res.data.message==='Email could not be sent to this email address')
      alert('Email could not be sent to this email address')
    else {
      alert('Link has been sent to this Email. Reset password and login again.');
      navigate('/');
    }
  }
  return (
    <div><section className="forget-banner">
    <div className="container">
      <div className="row">
        <div className="col-md-6 mx-auto">
          <div className="for-sec">
            <img src="images/us.jpg" />
            <h1>Forgot Password</h1>
            <p>Please Enter your registered E-mail address we will get back to you with the 
              reset password link and confirmation OTP thanks</p>
            <form>
                <input type="email" className="form-control" placeholder="Enter your email" required
                onChange={(e)=>setEmail(e.target.value)}
                />
                <input type="submit" className="btn btn-primary" defaultValue="Subscribe"
                onClick={(e)=>forgotPasswordHandler(e)}
                />
            </form></div>
        </div>
      </div>
    </div>
  </section>
  </div>
  )
}

export default ForgotPassword