import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Navbar from './Components/Navbar/Navbar';
import Layout from './Components/Layout';
import About from './Components/Pages/About/About';
import Contact from './Components/Pages/Contact/Contact';
import Login from './Components/Pages/auth/Login/Login';
import Signup from './Components/Pages/auth/Signup/Signup';
import ForgotPassword from './Components/Pages/auth/ForgotPassword/ForgotPassword';
import KeywordSearch from './Components/Pages/KeywordSearch';
import Footer from './Components/Footer';
import AdvanceSearch from './Components/Pages/AdvanceSearch';
import { useSelector } from 'react-redux';
import { getUser } from './apiServises/features/userSlice';
import Profile from './Components/Pages/Profile';
import ResetPassword from './Components/Pages/auth/ForgotPassword/ResetPassword';
import Support from './Components/Pages/Support';
import Summary from './Components/Pages/Summary';
import UploadBatch from './Components/Pages/UploadBatch';
import Batch from './Components/Pages/Batch';
import TenderTools from './Components/Pages/TenderTools';
import News from './Components/Pages/News';
import LayoutUnauth from './Components/LayoutUnauth';



function App() {
  let user = useSelector(getUser);
  console.log(user);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={user ? <Layout /> : <LayoutUnauth />}>
            <Route index element={user ? <AdvanceSearch /> : <Login />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="upload-batch" element={user ? <UploadBatch /> : <Login />} />
            <Route path="tender-tools" element={user ? <TenderTools /> : <Login />} />
            <Route path="batch" element={<Batch />} />
            <Route path="keyword-search" element={user ? <KeywordSearch /> : <Login />} />
            <Route path="news" element={user ? <News /> : <Login />} />
            <Route path="advance-search" element={user ? <AdvanceSearch /> : <Login />} />
            <Route path="profile" element={user ? <Profile /> : <Login />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="support" element={<Support />} />
            <Route path="summary/:id" element={<Summary />} />
          </Route>
          <Route path="*" element={<div className='d-flex justify-content-center align-items-center'> <h1 className='text-center'>Error 404 Page not found.</h1></div>} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
