import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import "./Login.css";
import { useDispatch, useSelector } from "react-redux";
import { getUser, login } from "../../../../apiServises/features/userSlice";

const Login = () => {
  document.title="ProcurBuzz - Login"

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let user = useSelector(getUser);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const loginHandler = async (e) => {
    e.preventDefault();
    if (email === "" || password === "") alert("All fields are mandatory!");
    else {
      let login_data = { email, password };
      dispatch(login(login_data));
      setEmail("");
      setPassword("");
      navigate('/');
    }
  };
  useEffect(() => {
   
    if (user) {
      navigate('/');
    }
  },[])
  
  return (
      !user &&
    <>
      <section className="banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="col-md-6"> </div>
              <div className="col-md-4 float-end">
                <div className="login-section">
                  <div className="login-head">
                    <h1>LOGIN</h1>
                  </div>
                  <form>
                    <div className="mb-3">
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="password"
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="forgot">
                      <Link
                        to="/forgot-password"
                        className="forgot-password-link"
                      >
                        Forgot password?
                      </Link>
                    </div>
                    <p>
                      By clicking “Login” you are agreeing to be bound by the{" "}
                      <a href="#">Term of Use</a>
                    </p>
                    {/* <div>
                      <p>New user?</p><span>
                      <Link to="/signup" className="forgot-password-link">
                        Signup Here
                      </Link></span>
                    </div> */}

                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={(e) => loginHandler(e)}
                    >
                      Login
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
