import React, { useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom'
import { useIdleTimer } from 'react-idle-timer'
import { getUser, logout } from "../apiServises/features/userSlice";



const Footer = () => {
  // const user = useSelector(getUser);

  let user = useSelector(getUser);



  return (
    <><footer style={{position:'relative'}}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 ">
            <p className='float-start terms'> <a href='https://datanstats.com/terms.html' target='_blank'>Terms of use </a>| <a href='https://datanstats.com/privacy-policy.html' target='_blank'>Privacy Policy </a>| <a href=''>Customer Care</a></p>
            <p className='float-start copyright' >Copyright © 2022, <a href="https://www.datanstats.com" target="_blank" >Data N Stats</a>. All right reserved. Reproduction in whole or in part without permission is prohibited.</p>
          </div>
          {!user &&

            <div className="col-md-4 mx-auto">
              <img src={`../../../images/aatma.png`} className='img make-in-india' style={{ width: '80px' }} />
              <img src={`../../../images/makein.png`} className='img make-in-india' style={{ width: '140px' }} />

            </div>
          } </div>
      </div>
    </footer>
    </>
  )
}

export default Footer